import { useCallback } from "react";
import { useQuery } from "react-query";
import apiInstance from "~/services/axios-instance";

const transformBeneficiaries = (data, accountId) => {
  const sorted = data
    .filter((item) => item.account_id === Number(accountId))
    .sort((x, y) => {
      // sort the array to show primary values first
      return x.is_primary === y.is_primary ? 0 : x.is_primary ? -1 : 1;
    });
  return sorted;
};

export const beneficiariesQueryKey = ["beneficiaries"];

const getBeneficiaries = async () => {
  const { data } = await apiInstance.get(`/beneficiary/`);
  return data;
};

const useBeneficiaries = (accountId, options = {}) => {
  return useQuery(beneficiariesQueryKey, getBeneficiaries, {
    enabled: !!accountId,
    staleTime: 1000,
    select: useCallback((data) => transformBeneficiaries(data, accountId), [accountId]),
    ...options,
  });
};

const transformBeneficiariesData = (data) => {
  const sorted = data.data.sort((x, y) => {
    // sort the array to show primary values first
    return x.is_primary === y.is_primary ? 0 : x.is_primary ? -1 : 1;
  });
  return {
    ...data,
    data: [...sorted],
  };
};

export const useBeneficiariesNew = ({ individualId }) => {
  return useQuery({
    queryKey: ["individual", { individualId }, "beneficiaries"],
    enabled: !!individualId,
    select: transformBeneficiariesData,
    queryFn: async () => {
      const { data } = await apiInstance.get(
        `individuals/${individualId}/account/beneficiaries`
      );
      return data;
    },
  });
};

export const useBeneficiary = ({ individualId, beneficiaryId }) => {
  return useQuery({
    queryKey: ["individual", { individualId }, "beneficiaries", beneficiaryId],
    enabled: !!individualId && !!beneficiaryId,
    queryFn: async () => {
      const { data } = await apiInstance.get(
        `individuals/${individualId}/account/beneficiaries/${beneficiaryId}`
      );
      return data;
    },
  });
};

export default useBeneficiaries;
